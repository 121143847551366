@use 'variables' as var;

/**
 * Style of outline (for focused elements)
 */
@mixin outline() {
  outline: var.$clr-ff-teal auto 1px;
  outline-offset: 3px;
}

/**
 * The "skip to main content"-link for keyboard users
 */
.skiplink {
  position: absolute;
  left: -200vw;
  top: 6px;

  &:focus {
    left: var.$site-padding-horizontal-mob;

    @media (min-width: var.$breakpoint-pc-min-width) {
      left: var.$site-padding-horizontal-pc;
    }
  }
}
