@use 'sass:color';
@use '../../abstracts/colors/palette';
@use '../../abstracts/colors/semantic/button';
@use '../../abstracts/colors/semantic/link';
@use '../../abstracts/colors/semantic/text';
@use '../../base/typography' as typ;

@mixin button-grid($gap: 0.5rem) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  margin-block-start: 1rem;

  &--inline {
    @extend .ff-button-grid !optional;

    align-items: center;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: flex-start;
  }
}

@mixin private-button-grid-styles() {
  .ff-button-grid {
    @include button-grid;
  }
}

@mixin primary() {
  &--red,
  &--primary {
    background-color: button.$ff-btn-clr-primary-bg;
    box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.1);
    color: button.$ff-btn-clr-primary-text;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: button.$ff-btn-clr-primary-text;
      text-decoration: none;
    }

    &:hover {
      background: button.$ff-btn-clr-primary-bg-hover;
    }
  }
}

@mixin secondary() {
  &--gray,
  &--secondary {
    background: palette.$ff-gray-100;
    box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.1);
    color: palette.$ff-white-100;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: palette.$ff-white-100;
      text-decoration: none;
    }

    &:hover {
      background-color: palette.$ff-gray-120;
    }

    &:focus-visible {
      box-shadow: 0px -2px 0px 0px #a29f9c inset;
    }
  }
}

@mixin sand() {
  &--sand {
    background-color: button.$ff-btn-clr-secondary-bg;
    box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.1);
    color: button.$ff-btn-clr-secondary-text;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: button.$ff-btn-clr-secondary-text;
      text-decoration: none;
    }

    &:hover {
      background: button.$ff-btn-clr-secondary-bg-hover;
    }
  }
}

@mixin white() {
  &--white {
    background-color: palette.$ff-white-100;
    box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.1);
    color: text.$ff-text-clr-100;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: text.$ff-text-clr-100;
      text-decoration: none;
    }

    &:hover {
      background: color.adjust(palette.$ff-white-100, $lightness: 5%);
    }
  }
}

@mixin transparent() {
  &--transparent {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }

    &:hover,
    &:disabled {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

@mixin button() {
  @include typ.semibold;

  align-items: center;
  background-color: palette.$ff-white-100;
  border: 1.5px solid transparent;
  border-radius: 5px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none;
  padding-block: 11px;
  padding-inline: 17px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition:
    background-color 0.15s ease-in-out,
    color 0.15s ease-in-out;
  user-select: none;

  @include primary;
  @include secondary;
  @include sand;
  @include transparent;
  @include white;

  /* Size */

  &--stretch {
    width: 100%;
  }

  &--mini {
    @include typ.size-12;
    line-height: 1rem;
    padding: 6px 12px;
  }

  &--small {
    @include typ.size-14;
    padding: 7px 18px 8px 18px;
  }

  &--medium {
    @include typ.size-16;
    padding: 8px 18px;
  }

  &--large {
    @include typ.size-19;
  }

  &--extra-large {
    @include typ.size-24;
    padding: 18.75px 22.5px 20px 22.5px;
  }

  /* State */

  &:active {
    &:not(:disabled) {
      transform: translateY(1px);
    }
  }

  &:focus-visible {
    outline: text.$ff-text-clr-100 auto 1px;
    outline-offset: 1px;
  }

  &:disabled:hover,
  &:disabled {
    cursor: not-allowed;
    background-color: button.$ff-btn-clr-disabled-bg;
    box-shadow: none;
    color: button.$ff-btn-clr-disabled-text;
  }

  /* button type */

  &--link {
    color: link.$ff-link-clr;
    font-weight: normal;
    background-color: transparent;
    text-decoration: underline;
    box-shadow: none;
    text-align: left;
    justify-content: left;
    padding: 2px 0;

    &:hover {
      background-color: transparent;
      text-decoration: none;
      color: link.$ff-link-clr-hover;
    }
  }
}

@mixin private-button-styles() {
  .ff-button {
    @include button;
  }
}
