/* You can add global styles to this file, and also import other style files */
@use '@fagforbundet/ngx-components' as ff;

@use 'utilities';
@use 'typography';
@use 'buttons';

@include ff.reset();
@include ff.base();

ul {
  list-style-type: none;
}
