@use 'sass:color';
@use 'utilities';
@use 'variables' as var;

.buttons {
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: stretch;
  margin-block-start: var.$vertical-gutter;
  width: 100%;

  /*
   * As equal width columns in a fullwidth grid
   * https://moderncss.dev/solutions-to-replace-the-12-column-grid/
   */
  &--grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  /* Each btn fits its content */
  &--inline {
    align-items: center;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: flex-start;
  }
}
