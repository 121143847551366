@mixin menu-boxes() {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 300px));
  gap: 12px;
}

@mixin private-menu-boxes-styles() {
  .ff-menu-boxes {
    @include menu-boxes;
  }
}
