@use 'variables';
@use 'utilities';

* {
  font-family: 'Source Sans Pro', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.1;
  text-align: left;
  color: variables.$clr-text-100;
}

h1 {
  font-size: 1.5rem;
  line-height: 30px;
  margin: 0.67em 0 0.6em;

  @media (min-width: variables.$breakpoint-pad-min-width) {
    font-size: 2rem;
    line-height: 40px;
  }
}

h2 {
  font-size: 1.4em;
  margin: 0.75em 0 0.4em;
}

h3 {
  font-size: 1.1em;
  margin: 0.83em 0 0.2em;
}

h4 {
  font-size: 1em;
  margin: 1.12em 0 0.2em;
}

h5 {
  font-size: 0.83em;
  margin: 1.5em 0 0.2em;
}

h6 {
  font-size: 0.75em;
  margin: 1.67em 0;
}

h1 + .subtitle,
h2 + .subtitle,
h3 + .subtitle {
  font-weight: 500;
  padding-top: 8px;

  @media (min-width: variables.$breakpoint-pad-min-width) {
    padding-top: 10px;
  }

  @media (min-width: variables.$breakpoint-pc-min-width) {
    padding-top: 15px;
  }
}

h1 > .subtitle,
h2 > .subtitle,
h3 > .subtitle {
  display: block;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2em;
  margin: 8px 0 0;

  @media (min-width: variables.$breakpoint-pad-min-width) {
    margin-top: 10px;
  }
  @media (min-width: variables.$breakpoint-pc-min-width) {
    margin-top: 15px;
  }
}

main h1:first-of-type,
main app-ff-action-title:has(h1):first-of-type {
  margin: 0 0 variables.$content-title-spacing-mob;

  @media (min-width: variables.$breakpoint-pad-min-width) {
    margin-bottom: variables.$content-title-spacing-pad;
  }
  @media (min-width: variables.$breakpoint-pc-min-width) {
    margin-bottom: variables.$content-title-spacing-pc;
  }

  + .subtitle {
    /* If there is a subtitle, absorb the margin from h1 and move it below subtitle */
    margin: -(variables.$content-title-spacing-mob) 0 variables.$content-title-spacing-mob;

    @media (min-width: variables.$breakpoint-pad-min-width) {
      margin: -(variables.$content-title-spacing-pad) 0 variables.$content-title-spacing-pad;
      padding-top: 10px;
    }

    @media (min-width: variables.$breakpoint-pc-min-width) {
      margin: -(variables.$content-title-spacing-pc) 0 variables.$content-title-spacing-pc;
      padding-top: 15px;
    }
  }
}

a,
a:link,
a:visited {
  color: variables.$clr-ff-link;
  cursor: pointer;
  text-decoration: underline;

  &:focus {
    @include utilities.outline();
  }

  &:hover {
    color: variables.$clr-ff-link-hover;
    text-decoration: none;
  }

  &.red {
    color: variables.$clr-ff-red;
  }
}

a:not([href]):not([routerLink]) {
  cursor: text;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}
