@use '../../base/typography';
@use '../../abstracts/colors/semantic/text';

@mixin label() {
  @include typography.semibold;

  color: text.$ff-text-clr-100;
  cursor: pointer;

  gap: 6px;
  margin: 0;
}

@mixin private-label-styles() {
  .ff-label {
    @include label;
  }
}
